<template>
  <v-container>
    <h1 class="breadcrumbs">
      <v-icon large class="mt-n1">mdi-shield-crown-outline</v-icon
      ><router-link
        v-for="bc in breadcrumbs"
        :key="bc.name"
        :to="{ name: bc.name }"
        >{{ bc.meta.label }}</router-link
      >
    </h1>
    <div class="my-8" v-if="$route.name === 'Admin' || loading">
      <v-list elevation="1">
        <v-list-item :to="{ name: 'Stats' }">
          <v-list-item-content>
            <v-list-item-title>Nutzungsstatistiken</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :to="{ name: 'Tenants' }">
          <v-list-item-content>
            <v-list-item-title>Mandantenverwaltung</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :to="{ name: 'Roles2' }">
          <!-- <v-list-item-icon><v-icon>mdi-account-supervisor-circle</v-icon></v-list-item-icon> -->
          <v-list-item-content>
            <v-list-item-title>Benutzerrollen und -rechte</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


      </v-list>
      <div class="mt-4" v-if="adminData.users.filter(obj => !obj.role_id ).length > 0">
        <h3>Benutzer mit fehlender Mandanten- oder Rollenzuweisung</h3>
      <p class="mt-4">{{ adminData.users.filter(obj => !obj.role_id ) }}</p>
      </div>
    </div>
    <router-view v-else :adminData="adminData"></router-view>
  </v-container>
</template>

<script>
import store from "@/store";
export default {
  data() {
    return {
      loading: true,
      adminData: {
        tenants: [],
        users: []
      },
      breadcrumbs: [],
    };
  },
  methods: {
    async getAdminData(){
      console.log("gettings admin data...");
      this.adminData = await this.$store.dispatch("req", { route: "admin" });
    }
  },
  async created(){
    await this.getAdminData();
    this.loading = false;

  },

  beforeRouteUpdate(to, from, next) {
    this.breadcrumbs = to.matched;
    next();
  },
  beforeRouteEnter(to, from, next) {
    if (store.getters.current.permissions.TENANTS_MANAGE) {
      //store.state.ui.hideForecastSelector = true;
      store.commit("setTenant", null);
      next();
    } else {
      next({ name: "NotFound" });
    }
  },

  mounted() {
    this.breadcrumbs = this.$route.matched;
    this.$root.$on("messageFromChild", (arg) => {
      this.breadcrumbs = arg;
    });
    this.$root.$on("refreshAdminData", () => {
      this.getAdminData();
    });
  },
};
</script>

<style lang="scss">
.breadcrumbs {
  * + * {
    display: inline-block;
    margin-left: 0.25em;
    color: inherit !important;
    text-decoration: none;

    // &:hover{
    //   text-decoration:underline;
    // }

    &::after {
      content: " » ";
    }

    &:last-child::after {
      content: "";
    }
  }
}
</style>